<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>
<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listScoreDetailAPI,
  listScoreDetailSummaryAPI,
} from "@/api/vip/report/scoreQuery"; //会员充值接口

export default {
  name: "scoreQuery",
  components: {
    TablePage,
  },
  data() {
    return {
      options: {
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "会员积分查询列表",
        listNo: true, // 序号
        exportOption: {
          exportApi: "vipScoreQuery",
          exportName: "会员积分",
        },
        //搜索
        search: [
          {
            label: "业务时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "day",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
          {
            type: "filters",
            tip: "会员级别/会员卡号/会员名称/小票号/收银员名称/门店编码/门店名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
              { filter: "tels", label: "手机号" },
              { filter: "billNos", label: "小票号" },
              { filter: "cashierNames", label: "收银员" },
              { filter: "shopNos", label: "门店编码" },
              { filter: "shopNames", label: "门店名称" },
            ],
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "shopIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "viplevel",
            option: {
              filter: "vipLevelIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "vip",
            option: {
              filter: "vipIds",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          {
            label: "手机号",
            seniorSearch: true,
            type: "input",
            model: "",
            filter: "tel",
          },
          {
            type: "local",
            label: "积分类型",
            model: "",
            filter: "scoreTypes",
            seniorSearch: true,
            option: {
              clearable: true,
              data: [
                { label: "门店零售积分", value: 0 },
                { label: "后台积分调整", value: 1 },
                { label: "前台积分抵现扣减积分", value: 2 },
                { label: "前台积分换奖扣减积分 ", value: 3 },
                { label: "商城消费积分", value: 4 },
                { label: "商城签到积分", value: 5 },
                { label: "门店充值积分 ", value: 6 },
                { label: "线上充值积分", value: 7 },
                { label: "门店换卡积分", value: 8 },
                { label: "后台换卡积分", value: 9 },
                { label: "管理员操作清零积分", value: 10 },
                { label: "精准营销赠送积分", value: 11 },
                { label: "生日有礼赠送积分", value: 12 },
                { label: "入会有礼奖励积分", value: 13 },
                { label: "入会有礼推荐新人奖励", value: 14 },
                { label: "线上积分兑换", value: 15 },
                { label: "导入积分", value: 16 },
              ],
              label: "label",
              value: "value",
              multiple: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        tabColumnType: "detail",
        tabsColumns: [
          {
            title: "会员积分明细查询",
            type: "detail",
            getListApi: listScoreDetailAPI,
            getSummaryApi: listScoreDetailSummaryAPI,
            defaultBody: { summaryType: 1 },
            exportOption: {
              // exportApi: 'vipScoreQuery',
              fastExportUrl: "/api/system/vip/report/score/query/listScoreDetailExport",
              exportName: "会员积分明细查询",
            }, //表格
            columns: [
              {
                prop: "score_time",
                label: "日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "shopNo",
                label: "门店编号",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "billPosNo",
                label: "小票号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "tel",
                label: "手机号",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "cardStatusName",
                label: "卡状态",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "score",
                label: "本次积分",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "scoreTypeName",
                label: "积分类型",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "endScore",
                label: "本次剩余积分",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["score", "endScore"],
          },
          {
            title: "会员积分查询（按会员卡汇总）",
            type: "vip",
            getListApi: listScoreDetailAPI,
            getSummaryApi: listScoreDetailSummaryAPI,
            defaultBody: { summaryType: 2 },
            exportOption: {
              // exportApi: 'vipScoreQuery',
              fastExportUrl: "/api/system/vip/report/score/query/listScoreDetailExport",
              exportName: "会员积分查询（按会员卡汇总）",
            }, //表格
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "score",
                label: "本次积分",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["score"],
          },
          {
            title: "会员积分查询（按小票汇总）",
            type: "billNo",
            getListApi: listScoreDetailAPI,
            getSummaryApi: listScoreDetailSummaryAPI,
            defaultBody: { summaryType: 3 },
            exportOption: {
              // exportApi: 'vipScoreQuery',
              fastExportUrl: "/api/system/vip/report/score/query/listScoreDetailExport",
              exportName: "会员积分查询（按小票汇总）",
            }, //表格
            columns: [
              {
                prop: "score_time",
                label: "日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "billPosNo",
                label: "小票号/订单号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "billNo",
                label: "内部单号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "machineName",
                label: "收银机",
                minWidth: 150,
              },
              {
                prop: "cashierName",
                label: "收银员",
                minWidth: 150,
              },
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "vipNo",
                label: "会员卡号",
                minWidth: 180,
                align: "center",
              },
              {
                prop: "vipName",
                label: "会员名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "scoreTypeName",
                label: "积分类型",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "score",
                label: "本次积分",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["score"],
          },
          {
            title: "会员积分查询（按门店汇总）",
            type: "shop",
            getListApi: listScoreDetailAPI,
            getSummaryApi: listScoreDetailSummaryAPI,
            defaultBody: { summaryType: 4 },
            exportOption: {
              // exportApi: 'vipScoreQuery',
              fastExportUrl: "/api/system/vip/report/score/query/listScoreDetailExport",
              exportName: "会员积分查询（按门店汇总）",
            }, //表格
            columns: [
              {
                prop: "shopNo",
                label: "门店编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "shopName",
                label: "门店名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "score",
                label: "本次积分",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["score"],
          },
          {
            title: "会员积分查询（按日期汇总）",
            type: "date",
            getListApi: listScoreDetailAPI,
            getSummaryApi: listScoreDetailSummaryAPI,
            defaultBody: { summaryType: 5 },
            exportOption: {
              // exportApi: 'vipScoreQuery',
              fastExportUrl: "/api/system/vip/report/score/query/listScoreDetailExport",
              exportName: "会员积分查询（按日期汇总）",
            }, //表格
            columns: [
              {
                prop: "billDateStr",
                label: "日期",
                minWidth: 155,
                align: "center",
              },
              {
                prop: "score",
                label: "本次积分",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["score"],
          },
          {
            title: "会员积分查询（按会员级别汇总）",
            type: "vipLevel",
            getListApi: listScoreDetailAPI,
            getSummaryApi: listScoreDetailSummaryAPI,
            defaultBody: { summaryType: 6 },
            exportOption: {
              exportApi: "vipScoreQuery",
              fastExportUrl: "/api/system/vip/report/score/query/listScoreDetailExport",
              exportName: "会员积分查询（按会员级别汇总）",
            }, //表格
            columns: [
              {
                prop: "vipLevelNo",
                label: "级别编码",
                minWidth: 120,
                align: "center",
              },
              {
                prop: "vipLevelName",
                label: "级别名称",
                minWidth: 150,
                align: "center",
              },
              {
                prop: "score",
                label: "本次积分",
                minWidth: 120,
                align: "center",
              },
            ],
            summary: ["score"],
          },
        ],
      },
      shopList: [], //门店
      vipCardTreeOptions: [], //会员等级tree
      //会员卡级别树规则
      defaultProps: {
        children: "children",
        label: "vipLevelName",
      },
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "vipNo", name: "会员卡号" },
        { parameter: "vipName", name: "会员名称" },
        { parameter: "tel", name: "手机号" },
        { parameter: "shopNo", name: "门店编码" },
        { parameter: "shopName", name: "门店名称" },
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      //自定义搜索框字段转换 (门店)
      normalizerShop: {
        Id: "shopId",
        No: "shopNo",
        Name: "shopName",
      },
    };
  },
};
</script>
