<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage' //表格页面组件
import { plusQueryListAPI, getTotalVipOrderPayMoney } from '@/api/vip/plusVip/query' //  plus报表
let statusList = [
  { label: '待支付', value: '1' },
  { label: '支付中', value: '2' },
  { label: '支付完成', value: '3' }
]
export default {
  name: 'plusVipQuery',
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: false, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: 'PLUS订单列表',
        getListApi: plusQueryListAPI,
        getSummaryApi: getTotalVipOrderPayMoney,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'orderId',
        //搜索
        search: [
          {
            label: '购买时间',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '会员卡号/会员名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' },
              { filter: 'shopNos', label: '门店编号' },
              { filter: 'shopNames', label: '门店名称' },
            ]
          },
          this.$select({
            key: 'listShop',
            option: {
              filter: 'shopIds',
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'shop' })
                    }
                  }
                ]
              }
            }
          }),
          {
            type: 'input',
            label: '手机号',
            model: '',
            filter: 'tel',
            seniorSearch: true
          },
          {
            label: '状态',
            seniorSearch: true,
            type: 'local',
            model: '',
            filter: 'plusVipStatus',
            option: {
              data: statusList,
              label: 'label',
              value: 'value',
              filterable: true,
              multiple: true
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'vipName',
            label: '会员名称',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'tel',
            label: '手机号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'shopNo',
            label: '门店编号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'shopName',
            label: '门店名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'orderStatusName',
            label: '订单状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'vipLevelName',
            label: '原会员级别',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusVipLevelName',
            label: '现会员级别',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusCaseName',
            label: 'PLUS会员方案',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'plusVipStatusName',
            label: 'PLUS会员使用状态',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'plusStartTime',
            label: 'PLUS会员开始时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'plusValidTime',
            label: 'PLUS会员到期时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'payFinishTime',
            label: '购买时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'payModeName',
            label: '支付方式',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'payMoney',
            label: '实收金额',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          }
        ],
        summary: ["payMoney"],
      }
    }
  }
}
</script>
