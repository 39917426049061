<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options"> </TablePage>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage' //表格页面组件
import { plusInfoListAPI } from '@/api/vip/plusVip/query' //  plus报表
let statusList = [
  { label: '待使用', value: '0' },
  { label: '正常', value: '1' },
  { label: '已过期', value: '2' },
  { label: '已用完', value: '3' },
  { label: '已作废', value: '4' }
]
export default {
  name: 'plusVipInfo',
  components: { TablePage },
  data() {
    return {
      options: {
        mutiSelect: false, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        title: 'PLUS会员列表',
        getListApi: plusInfoListAPI,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'lineId',
        //搜索
        search: [
          {
            label: '购买时间',
            type: 'datetimerange',
            model: '',
            noReset: true,
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '会员卡号/会员名称',
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'vipNos', label: '会员卡号' },
              { filter: 'vipNames', label: '会员名称' }
            ]
          },
          {
            type: 'input',
            label: '手机号',
            model: '',
            filter: 'tel',
            seniorSearch: true
          },
          {
            label: '状态',
            seniorSearch: true,
            type: 'local',
            filter: 'plusVipStatus',
            model: ['1'],
            defaultValue: ['1'],
            option: {
              data: statusList,
              label: 'label',
              value: 'value',
              filterable: true,
              multiple: true
            }
          },
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        //表格
        columns: [
          {
            prop: 'vipNo',
            label: '会员卡号',
            minWidth: 180,
            align: 'center'
          },
          {
            prop: 'vipName',
            label: '会员名称',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'tel',
            label: '手机号',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusVipStatusName',
            label: 'PLUS状态',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'vipLevelName',
            label: '原会员级别',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusVipLevelName',
            label: '现会员级别',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusCaseName',
            label: 'PLUS方案',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'plusValidTime',
            label: 'PLUS会员有效期',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '购买时间',
            minWidth: 155,
            align: 'center'
          },
          {
            prop: 'allScore',
            label: '累计积分',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'usableScore',
            label: '可用积分',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'allConsumeMoney',
            label: '消费金额',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'usableFillMoney',
            label: '储蓄余额',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 155,
            align: 'center'
          }
        ]
      }
    }
  }
}
</script>
